const successColor = '#26be0a'; // bright blue
const warningColor = '#fce94f'; // bright yellow
const errorColor = '#ef2929'; // bright red
const defaultColor = '#ad7fa8'; // bright magenta

interface IResponseLogger {
  status: number;
  response: any;
  url: string;
  method: string;
}

interface IRequestLogger {
  method?: string;
  body: any;
  url: string;
}

interface IErrorLogger extends IRequestLogger {
  status: number;
  message: any;
}

type OnOff = 1 | 0;

const API_RESPONSE_LOGGER: OnOff = 0;
const API_ERROR_LOGGER: OnOff = 1;

export const responseLogger = ({
  method,
  status,
  response,
  url,
}: IResponseLogger) => {
  if (API_RESPONSE_LOGGER) {
    const color =
      status >= 200
        ? successColor
        : status >= 400
        ? warningColor
        : status >= 500
        ? errorColor
        : defaultColor;

    const emoji =
      status >= 200 ? '🟢' : status >= 400 ? '🟠' : status >= 500 ? '🔴' : '';

    console.log(
      '%c' +
        '📤 ' +
        method.toUpperCase() +
        ' ' +
        '%c' +
        ' ' +
        emoji +
        ' ' +
        status +
        ' ',
      `background: #222; color: #d3d7cf; font-weight: bold;`,
      `color: ${color}; font-weight:bold;`,
      url,
      '\n',
      response,
      '\n\n'
    );
  }
};

export const errorLogger = ({
  method = '',
  body,
  status,
  url,
  message,
}: IErrorLogger) => {
  if (API_ERROR_LOGGER) {
    console.log(
      '%c ' + status + ' 🔴 ERROR!!!!! \n' + method.toUpperCase(),
      `background: #222;color: ${errorColor};font-weight:bold;`,
      url + '\n',
      body,
      '\n',
      message,
      '\n'
    );
  }
};
